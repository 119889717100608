import React, { useState } from 'react';
import * as S from "./styles";
import {SiSemanticuireact} from "react-icons/si";
import { Button } from '@chakra-ui/react';
import { ExternalLinkIcon, SettingsIcon } from '@chakra-ui/icons';
import { UiSettingsModal } from '../UiSettingsModal/UiSettingsModal';

export const Header: React.FC = () => {

    const [showSettings, setShowSettings] = useState(false);

    function openLogs() {
        window.open("https://grafana.intellij.net/d/nWt4hnGSk/spaceless", "_blank");
    }

    return <>
        <S.Container>
            <S.Title><SiSemanticuireact/> SPACELESS</S.Title>
            <S.ButtonsContainer>
                <Button leftIcon={<SettingsIcon/>} colorScheme="whatsapp" onClick={() => setShowSettings(true)}>Settings</Button>
                <Button leftIcon={<ExternalLinkIcon/>} colorScheme="whatsapp" onClick={openLogs}>Grafana logs</Button>
            </S.ButtonsContainer>
        </S.Container>

        <UiSettingsModal open={showSettings} onClose={() => setShowSettings(false)}/>
    </>;

};
