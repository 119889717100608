import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import {instancesActions} from "../reducers/instances.reducer";
import {catchError, delay, from, map, of, retry, switchMap, throwError, timer} from "rxjs";
import {API} from "../../services/api";
import {PayloadAction} from "@reduxjs/toolkit";
import {RunInstanceRequest} from "../../dto/run-instance-request";
import {StopInstanceRequest} from "../../dto/stop-instance-request";
import {miscActions} from "../reducers/misc.reducer";
import {AxiosError} from "axios";
import { RootState } from '../store';

const loadInstances$: Epic = (action$) => action$.pipe(
    ofType(instancesActions.loadInstances),
    switchMap(() => from(API.getInstances()).pipe(
        map(instancesActions.onInstancesLoaded),
        catchError((response) => from([
            miscActions.onError(response),
            instancesActions.onInstancesLoadingFailure(),
        ])),
    )),
);

const onInstanceStarted$: Epic = (action$) => action$.pipe(
    ofType(instancesActions.onInstanceStarted),
    map(instancesActions.loadInstances),
);

const runInstance$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(instancesActions.runInstance),
    switchMap((action: PayloadAction<RunInstanceRequest>) => from(API.runBackend(action.payload)).pipe(
        switchMap((runResponse) => {
            return from((runResponse.runtimeIsReady && runResponse.connectionLink) ? of(runResponse.connectionLink) : API.getLink(action.payload.workspaceId, state$.value.miscState.useToolboxApiLinks)).pipe(
                switchMap((connectionLink) => from([
                    instancesActions.onInstanceStarted({connectionLink, workspaceId: action.payload.workspaceId}),
                    miscActions.showNotification({
                        title: "Instance created",
                        text: "Feel free connect and test it",
                        type: "success",
                    }),
                ])),
            );
        }),
        catchError((error: AxiosError) => {
            const status = error.response?.status ?? null;
            // 423 means "runtime is not ready yet", so in this case we'll throw it again, so it will be handled by retry-operator:
            if (status === 423) {
                return throwError(() => error);
            }
            return from([
                miscActions.onError(error),
                instancesActions.onInstanceStartFailure(),
            ]);
        }),
        retry({delay(error: AxiosError, retryCount) {
            const status = error.response?.status ?? null;
            return status === 423 ? timer(2000) : of();
        }}),
    )),
);

const runInstance2$: Epic = (action$) => action$.pipe(
    ofType(instancesActions.runInstance),
    delay(1000),
    map(() => instancesActions.loadInstances()),
);

const stopInstance$: Epic = (action$) => action$.pipe(
    ofType(instancesActions.stopInstance),
    switchMap((action: PayloadAction<StopInstanceRequest>) => from(API.stopInstance(action.payload)).pipe(
        map(() => instancesActions.onInstanceStopped()),
    )),
);

const onInstanceStopped$: Epic = (action$) => action$.pipe(
    ofType(instancesActions.onInstanceStopped),
    switchMap(() => from([
        instancesActions.loadInstances(),
        miscActions.showNotification({
            title: "Instance will be deleted",
            text: "It will take up to 10sec to delete a running instance",
            type: "success",
        }),
    ])),
);

export const instancesEpics = combineEpics(
    loadInstances$,
    onInstanceStarted$,
    runInstance$,
    runInstance2$,
    stopInstance$,
    onInstanceStopped$,
);
