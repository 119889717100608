import React, { useEffect, useState } from 'react';
import * as S from './styles';
import {
  Button,
  ButtonGroup,
  CardHeader,
  Heading,
  IconButton,
  Input,
  Select,
  Stack,
  Text
} from '@chakra-ui/react';
import { PodFile, Resources } from '../../dto/run-instance-request';
import { SettingsIcon, TimeIcon } from '@chakra-ui/icons';
import { InstanceSettingsModal } from '../InstanceSettingsModal/InstanceSettingsModal';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userActions } from '../../redux/reducers/user.reducer';
import { instancesActions, OnInstanceStartedActionPayload } from '../../redux/reducers/instances.reducer';
import { subscribeActionAfter } from 'redux-subscribe-action';
import { PayloadAction } from '@reduxjs/toolkit';
import { HistoryItem } from '../../dto/history-item';
import { HistoryModal } from '../HistoryModal/HistoryModal';

const AVAILABLE_IMAGES: { image: string, label: string }[] = [
  {
    image: 'gcr.io/jetbrains-academy/edurd/introduction-to-python-16630@sha256:136fca761fc797d91e6ad5f04143777298c24f72210c37d16d633d5c94681c5f',
    label: 'introduction to python'
  },
  {
    image: 'gcr.io/jetbrains-academy/edurd/python-libraries-numpy-18302@sha256:28e9e80824f8815a45b779bd0c32a4d71e551438ae03278f28388ab960b2d975',
    label: 'python libraries numpy'
  },
  {
    image: 'gcr.io/jetbrains-academy/edurd/machine-learning-101-18392@sha256:857bb572c06d0fee76f5f73a0de70ad72baf8e8847c5d394a84953bb1be81b5f',
    label: 'machine learning'
  },
  {
    image: 'gcr.io/jetbrains-academy/edurd/gateway-to-pandas-22686@sha256:464b7d771a23a1b9a6b0ecc0d4b6cc044a2c1e6a2c63a5e64baee29cf9ecf243',
    label: 'gateway to pandas'
  },
  { image: 'gcr.io/jetbrains-academy-dev/edurd/dev-kotlin-intro-ui-fix-21067:latest', label: 'kotlin intro (ui fix)' },
  { image: 'vladfedorenkov/lightweight-runtime-emulator:6', label: '* runtime emulator (spaceless debug)' },
];

const UID_LS_KEY = 'uid';
const USERNAME_LS_KEY = 'username';

interface CreatingInstanceData {
  image: string;
  workspaceId: string;
  requestedAt: Date;
  uid: string;
  username: string;
}

export const InstanceControls: React.FC = () => {

  const loading = useAppSelector(state => state.instancesState.creatingInstance);
  const instances = useAppSelector(state => state.instancesState.runningInstances);
  const toolboxApiLink = useAppSelector(state => state.miscState.useToolboxApiLinks);
  const [loadingTimer, setLoadingTimer] = useState<NodeJS.Timer | null>(null);

  const dispatch = useAppDispatch();

  const [timer, setTimer] = useState(0);
  const [result, setResult] = useState<OnInstanceStartedActionPayload | null>(null);
  const [resources, setResources] = useState<Resources>({ mcpu: 2000, memory: 4000, gpu: 0 });
  const [files, setFiles] = useState<PodFile[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [workspaceId, setWorkspaceId] = useState(generateWorkspaceId());
  const [imageDropdownValue, setImageDropdownValue] = useState(AVAILABLE_IMAGES[0].image);
  const [selectedImage, setSelectedImage] = useState(imageDropdownValue);
  const [uid, setUid] = useState(localStorage.getItem(UID_LS_KEY) ?? '');
  const [username, setUsername] = useState(localStorage.getItem(USERNAME_LS_KEY) ?? '');

  const [showHistory, setShowHistory] = useState(false);
  const [creatingData, setCreatingData] = useState<CreatingInstanceData | null>(null);

  const startAllowed = selectedImage.trim() !== '' && uid.trim() !== '' && workspaceId.trim() !== '' && username.trim() !== '';

  useEffect(() => subscribeActionAfter(instancesActions.onInstanceStarted.type, (action) => {
    setResult((action as PayloadAction<OnInstanceStartedActionPayload>).payload);
    loadingTimer && clearInterval(loadingTimer) || console.warn('No timer to reset', loadingTimer);
    setWorkspaceId(generateWorkspaceId()); // generate a new one for next instances
  }), [timer]);

  useEffect(() => subscribeActionAfter(instancesActions.onInstanceStartFailure.type, () => {
    setTimer(0);
    if (loadingTimer) {
      clearInterval(loadingTimer);
      setLoadingTimer(null);
    }
  }));

  useEffect(() => {
    const instance = instances?.find(i => i.workspaceId === creatingData?.workspaceId);
    if (instance !== undefined) {
      const historyItem: HistoryItem = {
        workspaceId: instance.workspaceId,
        podName: instance.podName,
        image: instance.image,
        mcpu: instance.mcpu,
        memory: instance.memory,
        gpu: instance.gpu,
        requestedAt: creatingData!.requestedAt.toLocaleString('ru-RU'),
        uid: creatingData!.uid,
        username: creatingData!.username,
      };
      dispatch(userActions.addWorkspaceToHistory(historyItem));
      setCreatingData(null);
    }
  }, [instances]);

  function recreate(id: string, image: string, mcpu: number, memory: number, gpu: number, uid: string, username: string) {
    setShowHistory(false);
    run(image, {mcpu, memory, gpu}, id, uid, username);
  }

  function run(image: string, resources: Resources, workspaceId: string, uid: string, username: string) {
    if (image === '') {
      throw Error();
    }
    const interval = setInterval(() => {
      setTimer(curr => curr + 100);
    }, 100);
    setLoadingTimer(interval);
    dispatch(instancesActions.runInstance({
      dockerImage: image,
      resources,
      workspaceId,
      uid,
      username,
      toolboxApiLink,
      customAnnotations: {
        startedFrom: "spaceless-ui",  // not really needed, just for fun actually
      },
      files,
    }));
    setCreatingData({
      image,
      workspaceId,
      requestedAt: new Date(),
      uid,
      username,
    });
  }

  async function connect() {
    const link = result!.connectionLink;
    window.open(link, '_blank')!.focus();
  }

  function saveResourcesSettings(mcpu: number, memory: number, gpu: boolean, files: PodFile[]) {
    setResources({ mcpu, memory, gpu: gpu ? 1 : 0 });
    setShowModal(false);
    setFiles(files);
  }

  function reset() {
    setTimer(0);
    setResult(null);
  }

  function onDropdownChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setImageDropdownValue(value);
    if (value !== 'custom') {
      setSelectedImage(value);
    }
  }

  function onUidChange(value: string) {
    const sanitizedValue = value.replace(/[^-a-zA-Z0-9]/g, '');
    setUid(sanitizedValue);
    localStorage.setItem(UID_LS_KEY, sanitizedValue);
  }

  function onUsernameChange(value: string) {
    const sanitizedValue = value.replace(/[^-a-zA-Z0-9]/g, '');
    setUsername(sanitizedValue);
    localStorage.setItem(USERNAME_LS_KEY, sanitizedValue);
  }

  const button = result === null
    ? <div>
      <Text>Runtime image:</Text>
      <Select placeholder="Select project" value={imageDropdownValue} onChange={onDropdownChange}
              style={{ marginBottom: '4px' }}>
        {[...AVAILABLE_IMAGES, { label: 'Custom', image: 'custom' }].map(imageDescriptor => <option
          key={imageDescriptor.image} value={imageDescriptor.image}>
          {imageDescriptor.label}
        </option>)}
      </Select>
      {imageDropdownValue === 'custom' && <Input
          placeholder="Custom image name"
          value={selectedImage}
          onChange={e => setSelectedImage(e.target.value)}
      />}
      <div style={{ marginTop: '16px' }}>
        <Text>Preferred workspaceId:</Text>
        <Input
          placeholder="workspaceId"
          value={workspaceId}
          onChange={e => setWorkspaceId(e.target.value)}
        />
      </div>
      <div style={{ marginTop: '16px' }}>
        <Text>Your UID:</Text>
        <Input
          placeholder="UID"
          value={uid}
          onChange={e => onUidChange(e.target.value)}
        />
      </div>
      <div style={{ marginTop: '16px' }}>
        <Text>Your username:</Text>
        <Input
          placeholder="username"
          value={username}
          onChange={e => onUsernameChange(e.target.value)}
        />
      </div>
      <ButtonGroup isAttached style={{ marginTop: '16px' }} colorScheme="messenger">
        <Button
          onClick={() => run(selectedImage, resources, workspaceId, uid, username)}
          isLoading={loading}
          loadingText={`Starting (${(timer / 1000).toFixed(2)}s)`}
          style={{ minWidth: '200px', fontFamily: '\'Inconsolata\', monospace' }}
          isDisabled={!startAllowed}
        >
          Run new backend
        </Button>
        <IconButton
          onClick={() => setShowHistory(true)}
          aria-label="resources settings"
          icon={<TimeIcon/>}
        />
        <IconButton
          onClick={() => setShowModal(true)}
          aria-label="resources settings"
          icon={<SettingsIcon/>}
          isDisabled={loading}
        />
      </ButtonGroup>
    </div>
    : <Stack direction="column">
      <Button onClick={connect} colorScheme="whatsapp" style={{ minWidth: '200px' }}>
        Connect
      </Button>
      <Button colorScheme="whatsapp" variant="ghost" onClick={reset}>
        Start one more
      </Button>
    </Stack>;

  return <S.Layout>
    <CardHeader>
      <Heading size="sm">
        New instance
      </Heading>
    </CardHeader>
    <S.CardContent>
      {button}
      {result && <>
          <S.KekLabel>Started in {(timer / 1000).toFixed(2)}s</S.KekLabel>
      </>}
      <InstanceSettingsModal
        currentMcpu={resources.mcpu}
        currentMemory={resources.memory}
        currentGpu={resources.gpu !== 0}
        open={showModal}
        onClose={() => setShowModal(false)}
        onSave={saveResourcesSettings}
      />
      <S.DownloadInfo>
        ⚠️ GW Plugin is required:
      </S.DownloadInfo>
      <iframe width="245px" height="48px" src="https://plugins.jetbrains.com/embeddable/install/22692"></iframe>
    </S.CardContent>

    <HistoryModal open={showHistory} onClose={() => setShowHistory(false)} onRecreate={recreate}/>
  </S.Layout>;

};

function generateWorkspaceId(): string {
  // return "-";
  return 'sample-ws-' + Date.now();
}
